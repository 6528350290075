import React from "react"
import Layout from "../components/js/layout"
import SEO from "../components/js/seo"

class NotionPage extends React.Component {

  state = { 
    tagsOn: [],
    tagsOpen: false 
  }

  render () {
    return (
      <Layout page="book">
        <SEO title="얄코 노션페이지"/>
        <section>
          <ul>
          </ul>
        </section>


      </Layout>
    )
  }

  componentDidMount () {
    window.location.replace('https://yalco.notion.site/c689e4eddff9474c868053542737890b?pvs=4')
  }

}


// const ShopsPage = () => {
// }

export default NotionPage